import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Action from '../atoms/Action';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import { COLOR_BLUE, COLOR_DARK_GRAY, COLOR_ORANGE, COLOR_TEXT } from '../styles/colors';
import SearchesFolder from '../types/SearchesFolder';
import PlayersFolder from '../types/PlayersFolder';
import MEDIA from '../styles/media';
import ConfirmDialog from '../organisms/ConfirmDialog';
import RemoveIcon from '../icons/RemoveIcon';

interface RemovePlayerAndFolderProps {
  className?: string;
  disabled?: boolean;
  onRemove: () => void;
  label?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  RemovePlayerAndFolder: {
    padding: theme.spacing(0, 1.5),
    color: COLOR_DARK_GRAY,
    outlineColor: COLOR_ORANGE,
    transition: 'color 0.3s',
    overflow: 'hidden',

    '&:hover': {
      color: COLOR_BLUE,
      textDecoration: 'underline',

      '& $text': {
        color: COLOR_BLUE,
      },
    },
  },
  disabled: {
    outlineColor: 'transparent',

    '& $text': {
      color: COLOR_DARK_GRAY,
    },

    '&:hover': {
      color: COLOR_DARK_GRAY,
      textDecoration: 'none',

      '& $text': {
        color: COLOR_DARK_GRAY,
      },
    },
  },
  icon: {
    width: '32px',
    height: '32px',
  },
  text: {
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1,
    color: COLOR_TEXT,
    transition: 'color 0.3s',
  },

  [MEDIA.MOBILE]: {
    icon: {
      width: '22px',
      height: '22px',
    },
    text: {
      display: 'none',
    },
  },
}), { name: RemovePlayerAndFolder.name });

export default function RemovePlayerAndFolder(props: RemovePlayerAndFolderProps) {
  const {
    className,
    disabled,
    onRemove,
    label = '',
  } = props;
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  function actionHandler() {
    if (label === 'Unshare') {
      setDialogOpen(true);
    } else {
      onRemove();
    };
  };

  function onConfirm() {
    onRemove();
    setDialogOpen(false);
  };

  function onClose() {
    setDialogOpen(false);
  };

  return (
    <>
      <Action
        className={clsx(classes.RemovePlayerAndFolder, disabled && classes.disabled, className)}
        icon={RemoveIcon}
        iconClassName={classes.icon}
        disabled={disabled}
        onClick={actionHandler}
      >
        <span className={classes.text}>{label}</span>
      </Action>
      <ConfirmDialog
        open={dialogOpen}
        title={'Are you sure you want to Unshare?'}
        onConfirm={onConfirm}
        onClose={onClose}
        onCancel={onClose}
        confirmActionText={label}
        cancelActionText={'Cancel'}
      />
    </>
  );
}

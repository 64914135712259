import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Action from '../atoms/Action';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import { COLOR_BLUE, COLOR_DARK_GRAY, COLOR_ORANGE, COLOR_TEXT } from '../styles/colors';
import SearchesFolder from '../types/SearchesFolder';
import PlayersFolder from '../types/PlayersFolder';
import MEDIA from '../styles/media';
import ShareIcon from '../icons/ShareIcon';

interface ShareToFolderProps {
  className?: string;
  folders: Array<SearchesFolder | PlayersFolder>;
  disabled?: boolean;
  onShare: (folder:SearchesFolder | PlayersFolder) => void;
  label?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  ShareToFolder: {
    padding: theme.spacing(0, 1.5),
    color: COLOR_DARK_GRAY,
    outlineColor: COLOR_ORANGE,
    transition: 'color 0.3s',
    overflow: 'hidden',

    '&:hover': {
      color: COLOR_BLUE,
      textDecoration: 'underline',

      '& $text': {
        color: COLOR_BLUE,
      },
    },
  },
  disabled: {
    outlineColor: 'transparent',

    '& $text': {
      color: COLOR_DARK_GRAY,
    },

    '&:hover': {
      color: COLOR_DARK_GRAY,
      textDecoration: 'none',

      '& $text': {
        color: COLOR_DARK_GRAY,
      },
    },
  },
  icon: {
    width: '32px',
    height: '32px',
  },
  text: {
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1,
    color: COLOR_TEXT,
    transition: 'color 0.3s',
  },

  dropDown: {},
  dropDownSelect: {
    height: '40px',
  },

  [MEDIA.MOBILE]: {
    icon: {
      width: '22px',
      height: '22px',
    },
    text: {
      display: 'none',
    },
  },
}), { name: ShareToFolder.name });

export default function ShareToFolder (props: ShareToFolderProps) {
  const {
    className,
    folders = [],
    disabled,
    onShare,
    label = '',
  } = props;
  const classes = useStyles();

  return (
    <>
      <Action
        className={clsx(classes.ShareToFolder, disabled && classes.disabled, className)}
        icon={ShareIcon}
        iconClassName={classes.icon}
        disabled={disabled}
        onClick={onShare}
      >
        <span className={classes.text}>{`${label ? label : 'Move to shared'} `}</span>
      </Action>
    </>
  );
}
